import React from "react";
import ReactTable from "react-table";
import { Button } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import moment from "moment-timezone";
import StatusText from "./StatusText";
import DownloadButton from "./DownloadButton";

const LearnersBookingsTable = (props) => (
  <ReactTable
    columns={[
      {
        Header: props.t("general.exam"),
        accessor: "Exam",
        id: "exam",
        sortable: true,
        filterable: true,
        filterMethod: (filter, row) =>
          row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase()),
      },
      {
        Header: props.t("general.date"),
        accessor: "Date",
        Cell: (row) => moment(row.original.Date).format("MMMM Do YYYY, h:mm a"),
        id: "bookingDate",
        sortable: true,
        sort: "desc",
        filterable: false,
      },
      {
        Header: props.t("general.status"),
        accessor: "Status",
        sortable: true,
        filterable: true,
        Cell: (row) => {
          return <StatusText status={row.original.Status} />;
        },
        id: "status",
        filterMethod: (filter, row) =>
          row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase()),
      },
      {
        Header: props.t("LearnerBookings.recordings"),
        accessor: "Recordings",
        Cell: (row) => {
          if (row.original.SessionCount > 0) {
            if (row.original.rec_id === null) {
              return (
                <Button
                  onClick={() =>
                    props.handleRequestRecordings({
                      booking_id: row.original.booking_id,
                      exam: row.original.Exam,
                      date: row.original.Date,
                    })
                  }
                >
                  {props.t("general.request")}
                </Button>
              );
            }

            if (row.original.Recordings === 0) {
              return "Request Pending";
            } else if (row.original.Recordings === 1) {
              return (
                <DownloadButton
                  url={`${process.env.REACT_APP_API_ADDRESS}/api/recordings/download/${row.original.rec_id}`}
                  text={props.t("general.download")}
                />
              );
            } else {
              return (
                <React.Fragment>
                  <Button
                    onClick={() =>
                      props.handleRequestRecordings({
                        booking_id: row.original.booking_id,
                        exam: row.original.Exam,
                        date: row.original.Date,
                      })
                    }
                  >
                    {props.t("LearnerBookings.requestDownloadAgain")}
                  </Button>
                </React.Fragment>
              );
            }
          }
          return null;
        },
        id: "recordings",
        sortable: false,
        filterable: false,
      },
    ]}
    data={props.data}
    defaultPageSize={10}
    className="-striped -highlight test"
    minRows={0}
  />
);

export default withTranslation()(LearnersBookingsTable);
