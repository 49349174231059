import axios from "axios";

/**
 * Create a new Exam
 * @param exam
 * @returns {AxiosPromise}
 */
export const createExam = (exam) => {
  return axios.post(
    process.env.REACT_APP_API_ADDRESS + "/api/examinations",
    {
      title: exam.title,
      description: exam.description,
      rules: exam.rules,
      student_rules: exam.student_rules,
      client_id: exam.client_id,
      available_from: exam.available_from,
      available_to: exam.available_to,
      duration: exam.duration,
      requires_payment: exam.requires_payment,
      cost: exam.cost,
      available_days: exam.available_days,
      available_from_time: exam.available_from_time,
      available_to_time: exam.available_to_time,
      capacity: exam.capacity,
      limit: exam.limit,
      limit_notice: exam.limit_notice,
      file_upload_required: exam.file_upload_required,
      file_upload_description: exam.file_upload_description,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

/**
 * Get all Exams.
 * @returns {Promise<AxiosResponse | void>}
 */
export const getExams = () => {
  return axios
    .get(process.env.REACT_APP_API_ADDRESS + "/api/examinations")
    .then((res) => res.data)
    .catch((error) => console.log(error));
};

/**
 * Get active Exams.
 * @returns {Promise<AxiosResponse | void>}
 */
export const getActiveExams = () => {
  return axios
    .get(process.env.REACT_APP_API_ADDRESS + "/api/examinations/active")
    .then((res) => res.data)
    .catch((error) => console.log(error));
};

/**
 * Get an exam from a room and user.
 * @returns {Promise<AxiosResponse | void>}
 */
export const getExam = (room, user) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_ADDRESS}/api/examinations/${room}/${user}`
    )
    .then((res) => res.data)
    .catch((error) => console.log(error));
};

/**
 * Get all Clients in System
 * @returns {Promise<AxiosResponse | void>}
 */
export const getClients = () => {
  return axios
    .get(process.env.REACT_APP_API_ADDRESS + "/api/clients")
    .then((res) => res.data)
    .catch((error) => console.log(error));
};

export const getBookingsForDate = (exam, date) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_ADDRESS}/api/examinations/bookings/${exam}/${date}`
    )
    .then((res) => res.data)
    .catch((error) => console.log(error));
};

export const getBookingsForUser = (exam, user) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_ADDRESS}/api/examinations/userbookings/${user}/${exam}`
    )
    .then((res) => res.data)
    .catch((error) => console.log(error));
};

/**
 * Delete a specified Exam
 * @param id
 * @returns {Promise<AxiosResponse | void>}
 */
export const deleteExam = (id) => {
  return axios
    .delete(process.env.REACT_APP_API_ADDRESS + "/api/examinations/" + id)
    .then((res) => res.data)
    .catch((error) => console.log(error));
};

/**
 * Update an Exam provided an Exam with an ID.
 * @param exam
 * @returns {AxiosPromise}
 */
export const updateExam = (exam) => {
  return axios.post(
    process.env.REACT_APP_API_ADDRESS + "/api/examinations/update",
    {
      id: exam.id,
      title: exam.title,
      description: exam.description,
      rules: exam.rules,
      client_id: exam.client_id,
      student_rules: exam.student_rules,
      available_from: exam.available_from,
      available_to: exam.available_to,
      duration: exam.duration,
      requires_payment: exam.requires_payment,
      cost: exam.cost,
      capacity: exam.capacity,
      limit: exam.limit,
      limit_notice: exam.limit_notice,
      available_days: exam.available_days,
      available_from_time: exam.available_from_time,
      available_to_time: exam.available_to_time,
      file_upload_required: exam.file_upload_required,
      file_upload_description: exam.file_upload_description,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

/**
 * Get an Exam's info based on ID.
 * @param examName
 * @returns {Promise<AxiosResponse | void>}
 */
export const getExamByName = (examName) => {
  return axios
    .get(process.env.REACT_APP_API_ADDRESS + "/api/examinations/" + examName)
    .then((res) => res.data)
    .catch((error) => console.log(error));
};

export const getNextAvailableBooking = async (exam, user) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_ADDRESS}/api/examinations/nextAvailable?exam=${exam}`
    );
    return res.data;
  } catch (error) {
    return console.log(error);
  }
};
